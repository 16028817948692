import React from 'react';
import { any, bool, string } from 'prop-types';
import cx from 'classnames';

import { getClickEl } from 'utils';

import {
  button,
  primary as primaryClass,
  secondary,
} from './styles.module.css';

const Button = ({ primary, children, className, ...props }) => {
  const { El, elProps } = getClickEl(props);

  return (
    <El
      className={cx(
        button,
        {
          [primaryClass]: primary,
          [secondary]: !primary,
        },
        className,
      )}
      {...elProps}
    >
      {children}
    </El>
  );
};

Button.propTypes = {
  primary: bool,
  className: string,
  children: any,
};

Button.defaultProps = {
  primary: false,
};

export default Button;
