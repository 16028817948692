import React, { useEffect, useState } from 'react';

import { CONTACT_METHOD } from 'const';
import { getUrlParams } from 'utils';
import { getPaymentRequest } from 'apiRequests';

import Page from 'site/Page';
import paymentSuccess from 'images/payment-success.svg';

import { img } from './styles.module.css';
import SmsOptIn from 'components/SmsOptIn';

const ConfirmationPage = ({ location }) => {
  const { id, key } = getUrlParams(location.search, 'id', 'key');
  console.log('=== render confirmation', { id, key });

  const [businessName, setBusinessName] = useState(null);
  const [contactMethod, setContactMethod] = useState(null);

  // fetch payment request
  useEffect(() => {
    getPaymentRequest(id, key)
      .then(({ businessName, clientPreferredMethod }) => {
        setBusinessName(businessName);
        setContactMethod(clientPreferredMethod);
        console.log({ clientPreferredMethod });
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [id, key]);

  return (
    <Page center title="Thank you!">
      <img className={img} src={paymentSuccess} role="presentation" />
      <h1 className="heading-1">Thank you for your payment!</h1>
      <p className="body-1">
        You will receive a message of your receipt shortly.
      </p>

      {contactMethod === CONTACT_METHOD.SMS ? (
        <SmsOptIn paymentParams={{ id, key }} businessName={businessName} />
      ) : null}
    </Page>
  );
};

export default ConfirmationPage;
