import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import cx from 'classnames';

import { smsOptIn } from 'apiRequests';

import Button from 'components/Button';
import { success, error } from 'components/Toast';

import { section, button, finePrint } from './styles.module.css';

const SmsOptIn = ({ businessName, paymentParams }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <section className={section}>
      <h2 className="heading-2">Don't miss out!</h2>

      <p className="body-1">
        Sign up now to receive text about special offers and deals from{' '}
        {businessName}
      </p>

      <Button
        primary
        className={button}
        disabled={isDisabled}
        onClick={() => {
          setIsDisabled(true);
          smsOptIn(paymentParams)
            .then(() => success('Thanks for signing up!'))
            .catch(() => {
              // show toast
              error('Something went wrong. Please try again.');
              setIsDisabled(false);
            });
        }}
      >
        Sign Up Now!
      </Button>

      <p className={cx('caption', finePrint)}>
        By signing up, you authorize {businessName} to send text messages with
        promotions and offers. Message and data rates apply. No purchase
        required.
      </p>
    </section>
  );
};

SmsOptIn.propTypes = {
  businessName: string,
  paymentParams: shape({
    id: string,
    key: string,
  }),
};

export default SmsOptIn;
